import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import { config } from 'lib/config'
import { store } from 'lib/store'
import type { Template } from 'types/template'

export const useTemplates = (options?: SWRConfiguration) => {
	const { data, mutate, isLoading, error } = useSWR<Template[]>(`${config.apiURL}/v1.0/templates`, options)

	if (error) {
		store.dispatch({
			type: 'toast/set',
			payload: { kind: 'error', timeout: 6000, message: 'Something went wrong' },
		})
	}

	return {
		templates: data,
		mutateTemplates: mutate,
		areTemplatesLoading: isLoading,
	}
}
