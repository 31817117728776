import { useRouter } from 'next/router'
import React from 'react'
import { useEffectOnce } from 'react-use'

import Redirect from './Redirect'
import { accountsSDK, getToken, removeToken, setToken } from 'lib/auth'
import { config } from 'lib/config'
import { fetchWithServiceUnavailableFailover } from 'lib/fetcher'
import { identifyUser } from 'lib/tracking'
import { User } from 'types/user'

const TEXT_DOMAINS_REGEX = /@\b(livechat(inc)?|openwidget|text)\b/

type Props = {
	children: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const UserContext = React.createContext<User>(undefined as any)

function OAuth({ children }: Props) {
	const router = useRouter()
	const [user, setUser] = React.useState<User | null>(null)

	useEffectOnce(() => {
		async function handleAuth() {
			try {
				let token = getToken()

				if (!token) {
					const { access_token } = await accountsSDK.redirect().authorizeData()
					token = access_token
				}

				try {
					const response = await fetchWithServiceUnavailableFailover(`${config.apiURL}/v1.0/user`, {
						headers: { Authorization: `Bearer ${token}` },
					})

					if (response.status === 401 || response.status === 424) {
						removeToken()
						accountsSDK.redirect().authorize()
					}

					if (response.status === 500) {
						throw new Error()
					}

					if (response.status === 403) {
						window.location.replace(`${config.accountsURL}/panel`)
					}

					if (response.status === 200) {
						const currentUser: User = await response.json()
						setUser({
							...currentUser,
							isTextMember:
								currentUser.email === 'openwidgetinstagramreview@outlook.com' ||
								Boolean(currentUser.email.match(TEXT_DOMAINS_REGEX)),
						})

						setToken(token)
						window.location.hash = ''
						identifyUser(currentUser)
					}
				} catch {
					router.push('/oops')
				}
			} catch {
				accountsSDK.redirect().authorize()
			}
		}

		if (!user) {
			handleAuth()
		}
	})

	if (!user) {
		return null
	}

	if (user.role === 'member' && router.asPath !== '/configurator/code-snippet') {
		return <Redirect to="/configurator/code-snippet" />
	}

	return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

export default OAuth
