import createSvgIcon from './createSvgIcon'

const Instagram = createSvgIcon(
	<path d="M10.4375,6.6875 C8.36643219,6.6875 6.6875,8.36643219 6.6875,10.4375 C6.6875,12.5085678 8.36643219,14.1875 10.4375,14.1875 C12.5085678,14.1875 14.1875,12.5085678 14.1875,10.4375 C14.1853469,8.36732467 12.5076753,6.68965307 10.4375,6.6875 Z M10.4375,12.3125 C9.40196609,12.3125 8.5625,11.4730339 8.5625,10.4375 C8.5625,9.40196609 9.40196609,8.5625 10.4375,8.5625 C11.4730339,8.5625 12.3125,9.40196609 12.3125,10.4375 C12.3125,11.4730339 11.4730339,12.3125 10.4375,12.3125 Z M14.1875,2 L6.6875,2 C4.09991453,2.00301358 2.00301358,4.09991453 2,6.6875 L2,14.1875 C2.00301358,16.7750855 4.09991453,18.8719864 6.6875,18.875 L14.1875,18.875 C16.7750855,18.8719864 18.8719864,16.7750855 18.875,14.1875 L18.875,6.6875 C18.8719864,4.09991453 16.7750855,2.00301358 14.1875,2 L14.1875,2 Z M17,14.1875 C17,15.7408009 15.7408009,17 14.1875,17 L6.6875,17 C5.13419914,17 3.875,15.7408009 3.875,14.1875 L3.875,6.6875 C3.875,5.13419914 5.13419914,3.875 6.6875,3.875 L14.1875,3.875 C15.7408009,3.875 17,5.13419914 17,6.6875 L17,14.1875 Z M15.75,6.375 C15.75,7.06535594 15.1903559,7.625 14.5,7.625 C13.8096441,7.625 13.25,7.06535594 13.25,6.375 C13.25,5.68464406 13.8096441,5.125 14.5,5.125 C15.1903559,5.125 15.75,5.68464406 15.75,6.375 Z" />,
	{
		size: 20,
		viewBox: '0 0 20 20',
	},
)

export default Instagram
