// eslint-disable-next-line no-restricted-imports
import { Identify, identify, init, track } from '@amplitude/analytics-browser'

import { getOperatingSystem, isMobile } from './helpers'
import { config } from 'lib/config'

const TEST_EMAIL_DOMAINS = 'livechat|livechatinc|openwidget'

const TEST_EMAIL_REGEX = new RegExp(`(?:[@]).*${TEST_EMAIL_DOMAINS}(?=[\\.])`)

export const EVENT_NAMES = {
	CONTACT_FORM_SAVED: 'Contact form saved',
	FAQ_SAVED: 'FAQ saved',
	PRODUCT_CARD_CREATED: 'Products card created',
	PRODUCT_CARD_CUSTOMIZED: 'Product card customized',
	PRODUCT_CARDS_SAVED: 'Product cards saved',
	CUSTOMIZATION_SAVED: 'Customization saved',
	ADVANCED_CUSTOMIZATION_SETTINGS_SAVED: 'Advanced customization settings saved',
	CODE_COPIED: 'Code copied',
	HELP_LINK_CLICKED: 'Help link clicked',
	INSTALL_PROMPT_LINK_CLICKED: 'Installation prompt link clicked',
	CONTACT_US_CLICKED: 'Contact us clicked',
	SECTION_OPENED: 'Section opened',
	USER_PROFILE_OPENED: 'User profile: Opened',
	USER_PROFILE_HELP_CENTER_CLICKED: 'User profile: Help Center clicked',
	SKIP_CLICKED: 'Skip clicked',
	CHANGE_WEBSITE_URL_CLICKED: 'Change website URL clicked',
	SEND_INSTRUCTIONS_CLICKED: 'Send instructions clicked',
	FEATURE_TOGGLED: 'Feature toggled',
	NAVIGATION_BACK_CLICKED: 'Navigation back clicked',
	MODAL_DISPLAYED: 'Modal displayed',
	MODAL_CLOSED: 'Modal closed',
	INSTAGRAM_CONNECT_CLICKED: 'Instagram connect clicked',
	INSTAGRAM_DISCONNECT_CLICKED: 'Instagram disconnect clicked',
	INSTAGRAM_CONNECT_SUCCESS: 'Instagram connect success',
	COMING_SOON_LINK_CLICKED: 'Coming soon link clicked',
	CUSTOM_WIDGET_LAUNCHER_LEARN_MORE_CLICKED: 'Custom widget launcher learn more clicked',
	HELPDESK_LEARN_HOW_IT_WORKS_CLICKED: 'HelpDesk: `Learn how it works` clicked',
	HELPDESK_TRY_CLICKED: 'HelpDesk: `Try HelpDesk` clicked',
	HELPDESK_DISCONNECT_CLICKED: 'HelpDesk: `Disconnect` clicked',
	HELPDESK_SEE_TICKETS_CLICKED: 'HelpDesk: `See HelpDesk tickets` clicked',
	HELPDESK_GO_TO_SETTINGS_CLICKED: 'HelpDesk: `Go to HelpDesk settings` clicked',
	HELPDESK_MANAGE_CLICKED: 'Contact Card: HelpDesk `Manage` clicked',
	LIVECHAT_GO_TO_CHATS_CLICKED: 'LiveChat: `Go to chats` clicked',
	LIVECHAT_GO_TO_SETTINGS_CLICKED: 'LiveChat: `LiveChat Settings` clicked',
	LIVECHAT_MANAGE_CLICKED: 'Contact Card: LiveChat `Manage` clicked',
	LIVECHAT_LEARN_HOW_IT_WORKS_CLICKED: 'LiveChat: `Learn how it works` clicked',
	LIVECHAT_TRY_CLICKED: 'LiveChat: `Try LiveChat` clicked',
	LIVECHAT_DISCONNECT_CLICKED: 'LiveChat: `Disconnect` clicked',
	GOOGLE_REVIEWS_SAVED: 'Google Reviews: saved',
	GOOGLE_REVIEWS_REMOVE_BUTTON_CLICKED: 'Google Reviews: Remove button clicked',
	GOOGLE_REVIEWS_REMOVE_CONFIRMATION_CLICKED: 'Google Reviews: Remove confirmation clicked',
	FILE_UPLOAD_SUCCESS: 'File uploaded successfully',
	FILE_UPLOAD_FAILURE: 'File upload failed',
	APPS_ADD_CLICKED: 'Apps: `Add` clicked',
	APPS_REMOVE_CLICKED: 'Apps: `Remove` clicked',
	APPS_ADD_WITH_PROPERTIES_CLICKED: 'Apps: `Add with properties` clicked',
	OPENAI_ASSISTANTS_CONNECT_CLICKED: 'OpenAI Assistants: `Connect` clicked',
	OPENAI_ASSISTANTS_CHANGE_API_KEY_CLICKED: 'OpenAI Assistants: `Change API key` clicked',
	OPENAI_ASSISTANTS_CONNECT_ASSISTANT: 'OpenAI Assistants connect assistant success',
	OPENAI_ASSISTANTS_DISCONNECT_CLICKED: 'OpenAI Assistants: `Disconnect` clicked',
	OPENAI_ASSISTANTS_CHANGE_CLICKED: 'OpenAI Assistants: `Change` clicked',
	OPENAI_CONFIGURATION_SAVE_BUTTON_CLICKED: 'OpenAI Assistants: `Save` button clicked',
	CUSTOM_LINK_ADDED: 'Custom link added',
	CUSTOM_LINKS_SAVED: 'Custom links saved',
}

let isAmplitudeInitialized = false

type UserPayload = {
	email: string
	accountId: string
	organizationId: string
}

export const initAmplitude = () => {
	if (!config.isTest && !config.isDev && config.amplitudeKey && typeof window !== 'undefined') {
		init(config.amplitudeKey)
		isAmplitudeInitialized = true
		const identifyOptions = new Identify()
		identifyOptions.set('is_mobile', isMobile())
		identifyOptions.set('operating_system', getOperatingSystem())
		identify(identifyOptions)
	}
}

export const identifyUser = ({ email, accountId, organizationId }: UserPayload) => {
	if (!isAmplitudeInitialized) {
		return
	}
	const identifyOptions = new Identify()
	identifyOptions.set('user_id', accountId)
	identifyOptions.set('email', email)
	identifyOptions.set('organization_id', organizationId)
	identifyOptions.set('is_test', TEST_EMAIL_REGEX.test(email))
	identify(identifyOptions)
}

export const trackAmplitudeEvent = (
	name: string,
	properties?: Record<string, string | string[] | boolean | number>,
) => {
	if (config.isDev) {
		console.log('\x1b[34m[Amplitude]\x1b[94m', name, properties)
		return
	}
	if (!isAmplitudeInitialized) {
		return
	}
	track(name, properties)
}
