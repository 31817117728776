import useSWR from 'swr'
import type { SWRConfiguration } from 'swr'

import useTemplateId from './useTemplateId'
import { config } from 'lib/config'
import type { InstagramFeatureData } from 'types/integrations'

export const useInstagramFeed = (shouldFetch: boolean, options?: SWRConfiguration) => {
	const { withTemplateId } = useTemplateId()

	const { data, isLoading, mutate } = useSWR<InstagramFeatureData>(
		shouldFetch ? withTemplateId(`${config.apiURL}/v1.0/integrations/instagram/feed`) : null,
		options,
	)

	return {
		instagramData: data,
		isInstagramDataLoading: isLoading,
		mutateInstagramData: mutate,
	}
}
